import React from 'react';

const InvestmentPackages = () => {
  return (
    <table
      width="100%"
      height="100%"
      border="0"
      cellpadding="10"
      cellspacing="0"
      className="forTexts"
    >
      {' '}
      <tbody>
        <tr>
          <td width="100%" height="100%" valign="top">
            {' '}
            <table cellspacing="1" cellpadding="2" border="0" width="100%">
              {' '}
              <tbody>
                <tr>
                  {' '}
                  <td colspan="5">
                    <b>Available Investment Packages:</b>
                  </td>{' '}
                </tr>
                <tr>
                  {' '}
                  {/* <td bgcolor="FFEA00" align="center">
                    <b>Order</b>
                  </td>{' '} */}
                  <td bgcolor="FFEA00" align="center">
                    <b>Package name</b>
                  </td>{' '}
                  <td bgcolor="FFEA00" align="center">
                    <b>Deposit (US$)</b>
                  </td>{' '}
                  <td bgcolor="FFEA00" align="center">
                    <b>Profit (%)</b>
                  </td>{' '}
                  <td bgcolor="FFEA00" align="center">
                    <b>-</b>
                  </td>{' '}
                </tr>{' '}
                <tr>
                  {' '}
                  {/* <td bgcolor="FFF9B3">
                    {' '}
                    <br />{' '}
                    <a href="?a=rates&amp;action=down&amp;id=1">[DOWN]</a>{' '}
                  </td>{' '} */}
                  <td bgcolor="FFF9B3">Plan 1</td>{' '}
                  <td bgcolor="FFF9B3">$1000.00 - $5000.00</td>{' '}
                  <td bgcolor="FFF9B3">15% after 24 hours </td>{' '}
                  {/* <td bgcolor="FFF9B3">
                    8.00% / on maturity
                    <br /> + return 100.00% principal
                  </td>{' '} */}
                  {/* <td bgcolor="FFF9B3" className="menutxt" align="right">
                    {' '}
                    <a href="?a=editrate&amp;id=1">[edit]</a>{' '}
                    <a
                      href="?a=deleterate&amp;id=1"
                      onclick="return confirm('  Are you sure delete this package? All users deposits in this package will be lost!  ')"
                    >
                      [delete]
                    </a>{' '}
                  </td>{' '} */}
                </tr>{' '}
                <tr>
                  {' '}
                  {/* <td bgcolor="FFF9B3">
                    {' '}
                    <br />{' '}
                    <a href="?a=rates&amp;action=down&amp;id=1">[DOWN]</a>{' '}
                  </td>{' '} */}
                  <td bgcolor="FFF9B3">Plan 2</td>{' '}
                  <td bgcolor="FFF9B3">$5000.00 - $10000.00</td>{' '}
                  <td bgcolor="FFF9B3">30% after 24 hours </td>{' '}
                  {/* <td bgcolor="FFF9B3">
                    8.00% / on maturity
                    <br /> + return 100.00% principal
                  </td>{' '} */}
                  {/* <td bgcolor="FFF9B3" className="menutxt" align="right">
                    {' '}
                    <a href="?a=editrate&amp;id=1">[edit]</a>{' '}
                    <a
                      href="?a=deleterate&amp;id=1"
                      onclick="return confirm('  Are you sure delete this package? All users deposits in this package will be lost!  ')"
                    >
                      [delete]
                    </a>{' '}
                  </td>{' '} */}
                </tr>{' '}

                <tr>
                  {' '}
                  {/* <td bgcolor="FFF9B3">
                    {' '}
                    <br />{' '}
                    <a href="?a=rates&amp;action=down&amp;id=1">[DOWN]</a>{' '}
                  </td>{' '} */}
                  <td bgcolor="FFF9B3" >Plan 3</td>{' '}
                  <td bgcolor="FFF9B3">$10000.00 - $50000.00</td>{' '}
                  <td bgcolor="FFF9B3">60% after 48 hours </td>{' '}
                  {/* <td bgcolor="FFF9B3">
                    8.00% / on maturity
                    <br /> + return 100.00% principal
                  </td>{' '} */}
                  {/* <td bgcolor="FFF9B3" className="menutxt" align="right">
                    {' '}
                    <a href="?a=editrate&amp;id=1">[edit]</a>{' '}
                    <a
                      href="?a=deleterate&amp;id=1"
                      onclick="return confirm('  Are you sure delete this package? All users deposits in this package will be lost!  ')"
                    >
                      [delete]
                    </a>{' '}
                  </td>{' '} */}
                </tr>{' '}
                <tr>
                  {' '}
                  {/* <td bgcolor="FFF9B3">
                    {' '}
                    <br />{' '}
                    <a href="?a=rates&amp;action=down&amp;id=1">[DOWN]</a>{' '}
                  </td>{' '} */}
                  <td bgcolor="FFF9B3">Plan 4</td>{' '}
                  <td bgcolor="FFF9B3">$50000.00 - Unlimited</td>{' '}
                  <td bgcolor="FFF9B3">100% after 72 hours </td>{' '}
                  {/* <td bgcolor="FFF9B3">
                    8.00% / on maturity
                    <br /> + return 100.00% principal
                  </td>{' '} */}
                  {/* <td bgcolor="FFF9B3" className="menutxt" align="right">
                    {' '}
                    <a href="?a=editrate&amp;id=1">[edit]</a>{' '}
                    <a
                      href="?a=deleterate&amp;id=1"
                      onclick="return confirm('  Are you sure delete this package? All users deposits in this package will be lost!  ')"
                    >
                      [delete]
                    </a>{' '}
                  </td>{' '} */}
                </tr>{' '}
                {/* <tr>
                  {' '}
                  <td colspan="4">
                    {' '}
                    <table
                      cellspacing="0"
                      cellpadding="2"
                      border="0"
                      width="66%"
                      align="right"
                    >
                      {' '}
                      <tbody>
                        <tr
                          onmouseover="bgColor='#FFECB0';"
                          onmouseout="bgColor='';"
                          bgcolor=""
                        >
                          {' '}
                          <td nowrap="" width="120">
                            Plan 1
                          </td>{' '}
                          <td align="right" nowrap="">
                            $50.00 - $5999.00 &nbsp; &nbsp;{' '}
                          </td>{' '}
                          <td align="right" nowrap="" width="60">
                            8.00%
                          </td>{' '}
                        </tr>{' '}
                        <tr>
                          <td colspan="5" align="right">
                            <small>
                              Total deposited: 283($151770.76) &nbsp; Active
                              deposits: 15($10210.61)
                            </small>
                          </td>{' '}
                        </tr>
                      </tbody>
                    </table>{' '}
                  </td>{' '}
                </tr>{' '} */}
                {/* <tr>
                  {' '}
                  <td bgcolor="FFF9B3">
                    {' '}
                    <a href="?a=rates&amp;action=up&amp;id=2">
                      [UP]
                    </a> <br />{' '}
                    <a href="?a=rates&amp;action=down&amp;id=2">[DOWN]</a>{' '}
                  </td>{' '}
                  <td bgcolor="FFF9B3">16% after 52 hours </td>{' '}
                  <td bgcolor="FFF9B3">$6000.00 - $10999.00</td>{' '}
                  <td bgcolor="FFF9B3">
                    16.00% / on maturity
                    <br /> + return 100.00% principal
                  </td>{' '}
                  <td bgcolor="FFF9B3" className="menutxt" align="right">
                    {' '}
                    <a href="?a=editrate&amp;id=2">[edit]</a>{' '}
                    <a
                      href="?a=deleterate&amp;id=2"
                      onclick="return confirm('  Are you sure delete this package? All users deposits in this package will be lost!  ')"
                    >
                      [delete]
                    </a>{' '}
                  </td>{' '}
                </tr>{' '} */}
                {/* <tr>
                  {' '}
                  <td colspan="4">
                    {' '}
                    <table
                      cellspacing="0"
                      cellpadding="2"
                      border="0"
                      width="66%"
                      align="right"
                    >
                      {' '}
                      <tbody>
                        <tr
                          onmouseover="bgColor='#FFECB0';"
                          onmouseout="bgColor='';"
                          bgcolor=""
                        >
                          {' '}
                          <td nowrap="" width="120">
                            Plan 2
                          </td>{' '}
                          <td align="right" nowrap="">
                            $6000.00 - $10999.00 &nbsp; &nbsp;{' '}
                          </td>{' '}
                          <td align="right" nowrap="" width="60">
                            16.00%
                          </td>{' '}
                        </tr>{' '}
                        <tr>
                          <td colspan="5" align="right">
                            <small>
                              Total deposited: 1($500.00) &nbsp; Active
                              deposits: 0($0.00)
                            </small>
                          </td>{' '}
                        </tr>
                      </tbody>
                    </table>{' '}
                  </td>{' '}
                </tr>{' '} */}
                {/* <tr>
                  {' '}
                  <td bgcolor="FFF9B3">
                    {' '}
                    <a href="?a=rates&amp;action=up&amp;id=3">
                      [UP]
                    </a> <br />{' '}
                  </td>{' '}
                  <td bgcolor="FFF9B3">24% after 4 days </td>{' '}
                  <td bgcolor="FFF9B3">$11000.00 - $∞</td>{' '}
                  <td bgcolor="FFF9B3">
                    24.00% / on maturity
                    <br /> + return 100.00% principal
                  </td>{' '}
                  <td bgcolor="FFF9B3" className="menutxt" align="right">
                    {' '}
                    <a href="?a=editrate&amp;id=3">[edit]</a>{' '}
                    <a
                      href="?a=deleterate&amp;id=3"
                      onclick="return confirm('  Are you sure delete this package? All users deposits in this package will be lost!  ')"
                    >
                      [delete]
                    </a>{' '}
                  </td>{' '}
                </tr>{' '} */}
                {/* <tr>
                  {' '}
                  <td colspan="4">
                    {' '}
                    <table
                      cellspacing="0"
                      cellpadding="2"
                      border="0"
                      width="66%"
                      align="right"
                    >
                      {' '}
                      <tbody>
                        <tr
                          onmouseover="bgColor='#FFECB0';"
                          onmouseout="bgColor='';"
                          bgcolor=""
                        >
                          {' '}
                          <td nowrap="" width="120">
                            Premium plan
                          </td>{' '}
                          <td align="right" nowrap="">
                            $11000.00 - $0.00 &nbsp; &nbsp;{' '}
                          </td>{' '}
                          <td align="right" nowrap="" width="60">
                            24.00%
                          </td>{' '}
                        </tr>{' '}
                        <tr>
                          <td colspan="5" align="right">
                            <small>
                              Total deposited: 1($17000.00) &nbsp; Active
                              deposits: 1($17000.00)
                            </small>
                          </td>{' '}
                        </tr>
                      </tbody>
                    </table>{' '}
                  </td>{' '}
                </tr>{' '} */}
              </tbody>
            </table>{' '}
            <br />{' '}
            {/* <table cellspacing="0" cellpadding="0" border="0" width="100%">
              {' '}
              <tbody>
                <tr>
                  <td>
                    {' '}
                    <form method="get">
                      <input
                        type="submit"
                        value="Add a new Investment Package"
                        className="sbmt"
                        size="15"
                      />{' '}
                    </form>{' '}
                  </td>
                  <td align="right">
                    {' '}
                    <form method="get">
                      <input type="submit" className="sbmt" size="15" />{' '}
                    </form>{' '}
                  </td>{' '}
                </tr>{' '}
              </tbody>
            </table>{' '} */}
            <br />
            <div className="alert alert-warning">
              {' '}
              Here are all your Investment packages:
              {/* <br /> You can create unlimited sets of investment packages with
              any settings and payout options. Also you can change status of any
              package.{' '}
              <li>
                {' '}
                Active package. All active users will receive earnings every pay
                period if made a deposit
              </li>{' '}
              <li>
                {' '}
                Inactive package. Users will not receive any earnings
              </li>{' '}
              <br />
              <br /> Here you can view, edit and delete your packages and plans.{' '} */}
            </div>
          </td>{' '}
        </tr>{' '}
      </tbody>
    </table>
  );
};

export default InvestmentPackages;
